import { tick, type SvelteComponent } from 'svelte'
import { writable } from 'svelte/store'

type AviableComponentList = Record<string, () => Promise<{ default: typeof SvelteComponent }>>
type Props = Record<string, any> | undefined
export function createGlobalComponentApi<T extends Record<string, Props>>(components: AviableComponentList) {
	const instances = new Map<string, { instance: SvelteComponent; active: ReturnType<typeof writable> }>()

	const open = async <U extends keyof T & string>(name: U, props?: T[U]) => {
		if (import.meta.env.SSR) return // los paneles solo se abren en el cliente
		if (instances.has(name)) return
		const Component = (await components[`./${name}.svelte`]()).default
		const active = writable(false)
		const instance = new Component({
			props: {
				active,
				...props
			},
			target: document.body
		})
		await tick()
		instances.set(name, { instance, active })
		active.set(true)
		window.dispatchEvent(new CustomEvent(`open-${name}`, { detail: props }))

		active.subscribe(async (value) => {
			if (!value) {
				window.dispatchEvent(new CustomEvent(`close-${name}`, { detail: props }))
				// dispose elements from DOM if active === false after a second
				// give time for animations to end
				setTimeout(() => {
					instance.$destroy()
					instances.delete(name)
				}, 300)
			}
		})
	}

	const close = (name: keyof T & string) => {
		const component = instances.get(name)
		if (component) {
			component.active.set(false)
		}
	}

	const on = <K extends keyof T & string>(
		name: K,
		ev: 'open' | 'close',
		callback: (opts: T[K]) => void,
		options?: boolean | AddEventListenerOptions
	) => {
		//@ts-expect-error no quiero que el evento `${ev}-${name}` se conosca
		window.addEventListener(`${ev}-${name}`, (e: CustomEvent<T[K]>) => callback(e.detail), options)
	}

	const isOpen = (name: keyof T & string) => {
		return instances.has(name)
	}

	return {
		open,
		isOpen,
		close,
		on
	}
}
