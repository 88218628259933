import { clientWritable } from '@chasi/manager/client/shared'
import type { Readable } from 'svelte/store'
import { derived, get } from 'svelte/store'
import type { DameSesionResult } from 'src/services/auth'
import { CNotifier } from '@chasi/ui'

export const sessionData = clientWritable<DameSesionResult | undefined>()
export const user = clientWritable<User | undefined>()
export const userAuthenticated: Readable<boolean | undefined> = derived([sessionData, user], ([s, u], set) => {
	if (s) {
		if (s.logado === false) set(false)
		else if (u) set(true)
	}
})

export const userName = derived(
	user,
	(uData, set) => {
		if (uData) {
			if (uData.mostrarAlias && uData.nickName) set(uData.nickName)
			else set(`${uData.nombre} ${uData.apellidos}`)
		}
	},
	''
)

const idsEmpresa = new Set([12, 13, 14, 18, 19, 20, 21, 22, 23, 24, 31]) // tiposClientes que corresponden a empresas
export function getUserType(tipoCliente?: number) {
	const EMPRESA = Boolean(tipoCliente && idsEmpresa.has(tipoCliente))
	const AFILIADO = tipoCliente === 11
	const LIBRERIA = tipoCliente === 30
	const PARTICULAR = !EMPRESA && !AFILIADO && !LIBRERIA
	return {
		AFILIADO,
		EMPRESA,
		LIBRERIA,
		PARTICULAR,
		toString() {
			if (EMPRESA) return 'empresa'
			if (AFILIADO) return 'afiliado'
			if (LIBRERIA) return 'librería'
			return 'particular'
		}
	}
}

export const userType = derived(
	user,
	(uData, set) => {
		if (uData) {
			const type = getUserType(uData.idTipoCliente)
			set(type)
		}
	},
	getUserType()
)

export const userMember = clientWritable<UserMember | undefined>()
export const lectureTimeData = clientWritable<MemberLectureTime | undefined>()

export type FormasDePagoUsuario = {
	formasPagoTarjerta?: FormasPagoTarjerta[]
	permiteSaldo: boolean
	importeSaldo: number
	moneda: string
	monedaAnterior: string
	listaSaldoTarjetas: ListaSaldoTarjeta[]
	mensajeSeguridad?: string
	mensajeCobro?: string
}

export type FormasPagoTarjerta = {
	titular: string
	esPrincipal: boolean
	idFormaPago: string
	paisCache: number
	tipoTarjeta: string
	ultimosDigitos: string
	caducidad: string
}

type ListaSaldoTarjeta = {
	idTipoPago: number
	importeSaldo: number
	moneda: string
	monedaAnterior: string
	movTarjetas?: MOVTarjeta[]
}

type MOVTarjeta = {
	fecha: string
	tipoMovimiento: string
	importe: number
	moneda: string
	monedaAnterior: string
}

export const formasDePago = clientWritable<FormasDePagoUsuario | undefined>()

export type EstadoCompraUnPaso = {
	activado: boolean
	codigo: number
	mostrar: boolean
	mensajeSeguridad?: string
	mensajeCobro?: string
}

export const compraUnPaso = clientWritable<EstadoCompraUnPaso | undefined>()

export type EstadoCDLPlus = {
	trialPeriod: boolean
	renewActivated: boolean
	deactivable: boolean
	trialPeriodAllowed: boolean
	entryDate: string
	expirationDate: string
	status: number
	codigo: number
	mensaje?: string
	url?: string
	mensajeSeguridad?: string
	mensajeCobro?: string
}

export const CDLPlus = clientWritable<EstadoCDLPlus | undefined>()

export const shippingAddressUser = clientWritable<DireccionEnvio[]>([])
export const invoiceAddressUser = clientWritable<DireccionEnvioFactura[]>([])

export type FormasDeEnvio = {
	seleccionado: boolean
	idMetodoEnvio: number
	nombreFormaEnvio: string
	textoAdicional: string
	textoAdicional2: string
}

export const formasEnvios = clientWritable<FormasDeEnvio[]>([])

export const userHasShipping = () => get(shippingAddressUser).length
export const userHasInvoice = () => get(invoiceAddressUser).find((v) => v.seleccionado)
export const userHasEnvios = () => get(formasEnvios).find((v) => v.seleccionado)
export const userHasCard = () => get(formasDePago)?.formasPagoTarjerta?.find((v) => v.esPrincipal)

export function usuarioTieneTodo() {
	try {
		const hasShipping = userHasShipping()
		const hasInvoice = userHasInvoice()
		const hasEnvios = userHasEnvios()
		const hasCard = userHasCard()
		const timeout = 1000 * 10
		if (!hasShipping) CNotifier.error({ title: 'Debes tener al menos una dirección de envío', timeout })
		if (!hasInvoice) CNotifier.error({ title: 'Debes tener al menos una dirección de facturación', timeout })
		if (!hasEnvios) CNotifier.error({ title: 'Debes seleccionar un método de envío', timeout })
		if (!hasCard) CNotifier.error({ title: 'Debes tener al menos una tarjeta como forma de pago', timeout })
		return Boolean(hasShipping && hasInvoice && hasEnvios && hasCard)
	} catch (e) {
		return false
	}
}
