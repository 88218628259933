import type { SvelteComponent } from 'svelte'
import { createGlobalComponentApi } from '../mountComponent'
import type { NormalizedProduct } from 'src/services/product'
import type { Writable } from 'svelte/store'

type AviableModals = {
	ModalAuthenticacion: undefined
	ModalOpiniones: { product: NormalizedProduct }
	ModalConfirmarPedido: { title: string; text: string; error: number; confirmado: Writable<boolean> }
	ModalPopupCambioPais: undefined
	ModalConfiramarAbandono: {
		title?: string
		subtitle?: string
		confirm?: () => Promise<void> | void
		cancel?: () => Promise<void> | void
		cancelText?: string
		confrimText?: string
	}
}

export type ModalProps<Name extends keyof AviableModals> = AviableModals[Name]

const panelComponents = import.meta.glob<{ default: typeof SvelteComponent }>('./*.svelte')

export const Modal = createGlobalComponentApi<AviableModals>(panelComponents)
