import { page, toRoute } from '@chasi/manager/client/shared'
import { PAIS_LANG } from 'src/config'
import http from 'src/helpers/http'
import { persistentWritable, waitForDefined } from 'src/helpers/utils'
import { get } from 'svelte/store'

type TagData = { id: string; add2catUrl: string; checkoutUrl: string }
const empathyAddedProducts = persistentWritable<TagData | null>('empathy-add2cart-products', null)
const empathyCheckoutProducts = persistentWritable<TagData[]>('empathy-checkout-products', [])
const DEFAULT_QUERIES = ['Libros de bolsillo', 'Novela romántica', 'Novela histórica', 'Novela negra']

let scriptLoaded = false
export function loadEmpathyScript() {
	if (import.meta.env.SSR) return
	if (scriptLoaded) return
	scriptLoaded = true
	if (location.search.includes('terceros')) return
	const script = document.createElement('script')
	script.setAttribute('src', 'https://x.empathy.co/x-cdl/app.js')
	script.setAttribute('type', 'module')
	script.onload = function handleLoad() {
		const w = window.innerWidth
		window.InterfaceX.init({
			instance: 'cdl',
			lang: 'es', // este es el idioma en el que search devuelve los resultados de busqueda. Antes usabamos searchLa
			uiLang: 'es', // este es el idioma en el que pintamos los literales en Front. Antes usabamos lang
			documentDirection: 'ltr',
			scope: w > 960 ? 'desktop' : w < 576 ? 'mobile' : 'tablet',
			currency: 'EUR',
			store: PAIS_LANG.replace('es-', ''),
			consent: true,
			// env: 'staging',
			// isSPA: true,
			queriesPreview: DEFAULT_QUERIES.map((q) => ({ title: q, query: q })),
			callbacks: {
				UserClickedAResult: (result: EmpathyClickResult) => {
					const [id] = result.id.split('-')
					const add2catUrl = genTagUrl(result.tagging.add2cart)
					const checkoutUrl = genTagUrl(result.tagging.checkout)
					empathyAddedProducts.set({ id, add2catUrl, checkoutUrl })
				}
			}
		})
		window.addEventListener('popstate', closeEmpathy)
		if (searchInput()) {
			openEmpathy()
		}
		setupTagging()
	}
	document.body.append(script)
}

export function openEmpathy(v?: any) {
	if (import.meta.env.SSR) return
	const value = typeof v === 'string' ? v : searchInput()
	loadEmpathyScript()
	try {
		console.log('calling "InterfaceX.search" with value', value)
		window.InterfaceX.search(value)
		waitForDefined(
			() => document.querySelector('.x-root-container')?.shadowRoot,
			(root) => {
				const input = root.querySelector('input')
				input?.focus()
				interceptLinkEmpathy(root)
				clearSerchInput()
			}
		)
		return true
	} catch (error) {
		// no nos importa
	}
}

function closeEmpathy() {
	window.InterfaceX && window.InterfaceX.close()
}

let intercepted = false
function interceptLinkEmpathy(empathyRoot: ShadowRoot) {
	if (intercepted) return
	empathyRoot.addEventListener('click', (e) => {
		try {
			const target = e.target as HTMLElement
			if (!target) return
			const anchor = target.closest('[href]')
			if (!anchor) return
			const link = anchor.getAttribute('href')
			if (!link) return
			const isRelative = link.startsWith('/')
			if (isRelative) return
			const newUrl = new URL(link)
			const relativeUrl = newUrl.href.replace(newUrl.origin, '')
			e.preventDefault()
			e.stopPropagation()
			toRoute(relativeUrl)
		} catch (error) { }
	})
	intercepted = true
}

function searchInput(): string {
	if (import.meta.env.SSR) return ''
	const searchInput = document.querySelector<HTMLInputElement>('#empathy-search')
	return searchInput?.value || ''
}

function clearSerchInput() {
	if (import.meta.env.SSR) return ''
	const searchInput = document.querySelector<HTMLInputElement>('#empathy-search')
	if (searchInput) searchInput.value = ''
}

if (!import.meta.env.SSR) {
	const currentPage = get(page)
	if (currentPage.query.query) {
		openEmpathy(currentPage.query.query)
	}
	window.addEventListener('mousemove', loadEmpathyScript, { once: true })
	window.addEventListener('scroll', loadEmpathyScript, { once: true })
}

function genTagUrl(empathyTag: Add2Cart) {
	const { params, url } = empathyTag
	const tagUrl = new URL(url)
	Object.entries(params).forEach(([name, value]) => {
		tagUrl.searchParams.append(name, `${value}`)
	})
	return tagUrl.href
}

let initialized = false
function setupTagging() {
	if (initialized) return
	http.onResponse(({ path, requestData, responseData }) => {
		initialized = true
		// productos añadidos desde empathy
		if (path.startsWith('/carrito/anadirProducto')) {
			const { idProducto } = requestData as { idProducto: number }
			const empathyResult = empathyAddedProducts.snapshot()
			if (empathyResult && +empathyResult.id === idProducto) {
				fetch(empathyResult.add2catUrl)
				empathyAddedProducts.set(null)
				empathyCheckoutProducts.update((p) => {
					p.push(empathyResult)
					return p
				})
			} else {
				empathyAddedProducts.set(null)
			}
		}
		// productos comprados añadidos desde empathy
		if (path.startsWith('/carrito/finalizarCompra')) {
			const checkoutResults = empathyCheckoutProducts.snapshot()
			const { listaPedido } = responseData as ConfirmacionCompra
			if (checkoutResults) {
				checkoutResults.forEach((p) => {
					const isInBasket = listaPedido.find((v) => v.lineasPedido.find((v2) => v2.producto?.id === +p.id))
					if (isInBasket) fetch(p.checkoutUrl)
				})
				empathyCheckoutProducts.set([])
			}
		}
	})
}

type EmpathyClickResult = {
	id: string
	images: string[]
	name: string
	url: string
	identifier: Identifier
	rating: Identifier
	price: Price
	type: string
	modelName: string
	isWishlisted: boolean
	tagging: Tagging
	availability: string
	description: string
	encuadernation: string
	authors: string[]
	isSigned: boolean
	freeShipping: boolean
	externalScore: number
	externalVotes: string
	isNovelty: boolean
	latamPrice: LatamPrice
	discount: number
	latamDiscount: number
}

type Identifier = {
	value: null | string
}

type LatamPrice = {
	futureValue: number
	hasDiscount: boolean
}

type Price = {
	value: number
	originalValue: number
	futureValue: number
	hasDiscount: boolean
}

type Tagging = {
	add2cart: Add2Cart
	checkout: Add2Cart
	click: Add2Cart
	displayClick: Add2Cart
}

type Add2Cart = {
	url: string
	params: Params
}

type Params = {
	lang: string
	scope: string
	store: string
	q: string
	productId: string
	url: string
	title: string
	position: string
	page: string
	follow: boolean
	type: string
	origin: string
	filters: string
	filtered: string
	spellcheck: string
	location?: string
	displayId?: string
	displayFamily?: string
}
