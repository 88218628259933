import { createClient } from '@chasi/manager/client/browser'
import { authenticate } from 'src/services/auth'
import { registerComponents } from './main'
import { exposeToMobileApp } from './terceros/mobile-app'
import { TAG_NAME } from './config'
import { loadEmpathyScript } from './terceros/empathy'
import { silenceWarning } from './helpers/utils'

silenceWarning("was created with unknown prop 'id'")

if (import.meta.env.DEV) {
	import('./components/_manager')
}

console.info({
	date: import.meta.env.VITE_BUILD_DATE,
	rama: import.meta.env.VITE_GIT_BRANCH_NAME,
	tag: TAG_NAME
})

async function startApp() {
	loadEmpathyScript()
	exposeToMobileApp()
	authenticate()
	registerComponents()
	await createClient(onErrror)

	if (!location.search.includes('terceros')) {
		import('./terceros/index')
	}
}

startApp()

function onErrror(err: unknown) {
	// esto es por que en los entornos si hay un codigo de error devuelve un html
	if (err instanceof SyntaxError && err.message.includes('is not valid JSON')) {
		location.reload()
	}
	console.error(err)
}
