import { CNotifier } from '@chasi/ui'
import { PAIS_CACHE } from 'src/config'
import http from 'src/helpers/http'
import { createUrl } from 'src/helpers/utils'
import { sessionData, userAuthenticated, lectureTimeData } from 'src/store/user'
import { fetchMemberData, fetchUserData } from './user'
import { fetchCart } from './checkout'
import { DEFAULT_QUERY_PARAMS } from './constants'
import { Modal } from 'src/lib/modals/modal'
import { toRoute } from '@chasi/manager/client/shared'
import Cookie from 'js-cookie'
import { updateRGPD } from './general'

export type DameSesionResult = {
	paisMoneda: string
	ipCliente: string
	logado: boolean
	simbolo: string
	flagVivlio: boolean
	flagPrecios: boolean
	mensajePopup?: string
	nombrePaisOrigen: string
	paisIp?: number
	redireccion?: string
}

export function fetchAllUserData(logged = true) {
	sessionData.update((s) => {
		if (s) s.logado = logged
		return s
	})
	return Promise.allSettled([fetchCart(), logged && fetchUserData(), logged && fetchMemberData()])
}
export let flagPrecio = true
export async function authenticate() {
	const employeeCookie = Cookie.get('employeeCred')
	if (employeeCookie) {
		try {
			const { email, password, idoficina } = JSON.parse(employeeCookie)
			await login(email, password, idoficina)
			Cookie.remove('employeeCred')
		} catch (error) {}
	}
	const qparams = new URLSearchParams(location.search)
	const campaignid = qparams.get('campaignid') // MAP-34328 mandamos solo estos parametros
	const utm_source = qparams.get('utm_source')
	const utm_medium = qparams.get('utm_medium')
	const gclid = qparams.get('gclid')
	if (campaignid || (utm_source && utm_medium) || gclid) {
		Cookie.set('campaign_url', encodeURI(location.href))
	}
	const url = createUrl('/comun/dameSesion', { paiscache: PAIS_CACHE, campaignid, utm_source, utm_medium, gclid })
	const response = await http.get<DameSesionResult>(url)
	sessionData.set(response)
	handlePopupPais(response.redireccion)
	flagPrecio = response.flagPrecios
	await fetchAllUserData(response.logado)
}

export async function login(email: string, password: string, idoficina?: string) {
	try {
		const params = new URLSearchParams()
		params.append('email', email)
		params.append('pass', password)
		params.append('paiscache', PAIS_CACHE)
		if (idoficina) {
			params.append('idoficina', idoficina)
		}
		await http.post('/usuario/login', null, {
			headers: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			body: params
		})
		if (location.pathname === '/checkout') location.reload()
		else await fetchAllUserData()
	} catch (error) {
		let msg = 'Se ha producido un error, por favor inténtalo más tarde.'
		if (error instanceof Response) {
			if (error.status === 404) {
				msg = 'Email o contraseña no válidos'
			}
		}
		return msg
	}
}

export async function logout(reload = true) {
	try {
		const params = new URLSearchParams()
		params.append('paiscache', PAIS_CACHE)
		params.append('ip', DEFAULT_QUERY_PARAMS.ip)
		await http.post('/usuario/logout', null, {
			headers: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			body: params
		})
		reload && location.reload()
		lectureTimeData.set(undefined) // tiempo de lectura eliminar para evitar que se haga login con otro usuario y se intercambien los datos
	} catch (error) {
		CNotifier.error('A ocurrido un error mientras se cierra la sesión')
	}
}
type RecordarPasswordResponse = {
	peticionOk: boolean
	codeException: number
	mensaje: string
}

export async function recoverPassword(email: string) {
	try {
		const params = new URLSearchParams()
		params.append('paiscache', PAIS_CACHE)
		params.append('ip', DEFAULT_QUERY_PARAMS.ip)
		params.append('email', email)
		await http.post<RecordarPasswordResponse>('/usuario/recordarPassword', null, {
			headers: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			body: params
		})
		return true
	} catch (error) {
		CNotifier.error('Error en la recuperación de la contraseña.')
		return false
	}
}

export type RegisterUserParams = {
	nombre: string
	apellidos: string
	email: string
	password: string
	telefono?: string
	tienda?: string
}
export async function registerUser(registerParams: RegisterUserParams, rgpd: RGPD) {
	try {
		const url = createUrl('/usuario/registrarUsuario', DEFAULT_QUERY_PARAMS)
		const { listaErrores } = await http.post<{ listaErrores?: string[] }>(url, registerParams)
		if (listaErrores) return listaErrores
		rgpd.email = registerParams.email
		await updateRGPD(rgpd)
		await fetchAllUserData()
	} catch (error) {
		return ['Se ha producido un error, por favor inténtalo más tarde.']
	}
}
export async function registerUserSocio(registerParams: RegisterUserParams, rgpd: RGPD) {
	try {
		const url = createUrl('/usuario/registrarUsuarioSocio', DEFAULT_QUERY_PARAMS)
		const { listaErrores } = await http.post<{ listaErrores?: string[] }>(url, registerParams)
		if (listaErrores) return listaErrores
		rgpd.email = registerParams.email
		await updateRGPD(rgpd)
		location.href = '/registry-ok'
	} catch (error) {
		return ['Se ha producido un error, por favor inténtalo más tarde.']
	}
}

export async function setNewPassword(password: string, nuevoPassword: string, nuevoPasswordRepetido: string) {
	try {
		const url = createUrl('/usuario/cambiarPassword', DEFAULT_QUERY_PARAMS)
		const response = await http.raw(url, { method: 'POST' }, { password, nuevoPassword, nuevoPasswordRepetido })
		const text = await response.text()
		if (text !== '"OK"') {
			CNotifier.error(text)
		} else {
			CNotifier.success('Contraseña cambiada correctamente')
		}
	} catch (error) {
		//@ts-ignore
		CNotifier.error(error.message)
	}
}

export function authIfNeeded(): Promise<void> {
	return new Promise((resolve, reject) => {
		const off = userAuthenticated.subscribe((isAuth) => {
			if (isAuth) {
				resolve()
				off()
			} else if (isAuth === false) {
				Modal.on('ModalAuthenticacion', 'close', () => {
					if (isAuth) resolve()
					else reject('Usuario no autenticado')
					off()
				})
				Modal.open('ModalAuthenticacion')
			}
		})
	})
}

export function restorePassword(password: string, token: string) {
	const url = createUrl('/usuario/restaurarPassword', DEFAULT_QUERY_PARAMS)
	const params = new URLSearchParams()
	params.append('password', password)
	params.append('restore', token)
	return http.post(url, null, {
		headers: {
			'content-type': 'application/x-www-form-urlencoded'
		},
		body: params
	})
}

export async function registrarEmpresa(data: Record<string, any>) {
	try {
		const url = createUrl('/usuario/registrarEmpresa', DEFAULT_QUERY_PARAMS)
		const params = new URLSearchParams()
		for (const key in data) {
			params.append(key, data[key])
		}
		const { listaErrores } = await http.post<{ listaErrores?: string[] }>(url, null, {
			headers: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			body: params
		})
		if (listaErrores) throw listaErrores
		await fetchAllUserData()
		toRoute('/registry-ok')
	} catch (error) {
		if (error instanceof Array) {
			error.forEach((err) => {
				CNotifier.error(err)
			})
		} else {
			CNotifier.error('Se ha producido un error, por favor inténtalo más tarde.')
		}
	}
}
function handlePopupPais(redirect: string | undefined) {
	if (!redirect) return
	if (localStorage.getItem('capaPaisClosed') === '1') return
	Modal.on(
		'ModalPopupCambioPais',
		'close',
		() => {
			localStorage.setItem('capaPaisClosed', '1')
		},
		{ once: true }
	)
	Modal.open('ModalPopupCambioPais')
}
