import { clientWritable } from '@chasi/manager/client/shared'
import { CNotifier } from '@chasi/ui'
import { PAIS } from 'src/config'
import { derived, get } from 'svelte/store'
import { persistentWritable } from 'src/helpers/utils'

type Compra = DatosCompra | ({ contenidoPedido: ContenidoPedido } & Partial<DatosCompra>) | undefined

export const datosCompra = clientWritable<Compra>()
export const datosCompraConfirmada = clientWritable<ConfirmacionCompra | undefined>()

export const basket = derived<typeof datosCompra, PedidoDatosCompra[]>(
	datosCompra,
	(compra, set) => {
		if (compra) {
			const result = []
			const currencyMap = compra.contenidoPedido.mapaPedidosPorMoneda
			for (const currencyType in currencyMap) {
				const content = currencyMap[currencyType]
				if (currencyType === '46') result.unshift(...content)
				else result.push(...content)
			}
			set(result)
		} else {
			set([])
		}
	},
	[]
)

export const basketStatus = derived(
	datosCompra,
	(compra, set) => {
		if (compra) {
			const status = {
				subtotal: 0,
				envio: 0,
				descuentoEnvio: 0,
				total: 0,
				estimado: false
			}
			const importes = compra.contenidoPedido.mapaImportesPorMoneda
			for (const moneda in importes) {
				const importeMoneda = importes[moneda]
				// si viene totalInvero usa los importes inversos (mexico y colombia)
				// quiere decir que estamos cobrando en euros, pero pintamos con un precio estimado
				if (importeMoneda.totalInverso) {
					status.estimado = true
					status.subtotal += importeMoneda.importeProductosInverso - importeMoneda.totalDescuentosProductosInverso
					status.envio += importeMoneda.gastosSinDescuentoInverso - importeMoneda.descuentoGastosInverso
					status.descuentoEnvio +=
						importeMoneda.descuentoGastosInverso + importeMoneda.totalDescuentosProductosInverso
					status.total += importeMoneda.totalInverso
				} else {
					status.subtotal += importeMoneda.importeProductos - importeMoneda.totalDescuentosProductos
					status.envio += importeMoneda.gastosSinDescuento - importeMoneda.descuentoGastos
					status.descuentoEnvio += importeMoneda.descuentoGastos + importeMoneda.totalDescuentosProductos
					status.total += importeMoneda.total
				}
			}
			set(status)
		}
	},
	{
		subtotal: 0,
		envio: 0,
		descuentoEnvio: 0,
		total: 0,
		estimado: false
	}
)

export const articulos = derived(
	basket,
	(basket, set) => {
		let total = 0
		if (basket.length) {
			basket.forEach((pedido) => {
				pedido.lineasPedidoResumen.forEach((producto) => {
					total += producto.unidades
				})
			})
		}
		set(total)
	},
	0
)

export const facturaDigital = derived<typeof datosCompra, boolean>(datosCompra, (compra, set) => {
	if (compra?.nuevaDirFacturacion && compra.compraDigital) {
		set(true)
	} else {
		set(false)
	}
})

// si estamos en el cliente
if (!import.meta.env.SSR) {
	const LABEL_PAIS = PAIS === 'Spain' ? 'España península y Baleares' : PAIS
	const oldBasket = persistentWritable<PedidoDatosCompra[]>('old-basket', [])
	let initialized = false
	basket.subscribe((b) => {
		if (!initialized) {
			initialized = true
			return
		}
		if (location.pathname !== '/checkout') return
		const oldBasketSnapshot = get(oldBasket)
		const focusElement = document.activeElement
		const borradoManual = focusElement?.closest('[name="restar-unidades"], [name="eliminar-producto"]')
		if (oldBasketSnapshot.length > b.length && !borradoManual) {
			const oldVendors = extractVendors(oldBasketSnapshot)
			const newVendors = extractVendors(b)
			const diff = oldVendors.difference(newVendors)
			if (diff.size) {
				for (const vendedor of diff) {
					CNotifier.error({
						title: diff.size > 1 ? 'Se han eliminado productos' : 'Se ha eliminado un producto',
						text: `El vendedor ${vendedor} solo está disponible para ${LABEL_PAIS}`,
						timeout: 1000 * 20
					})
				}
			}
		}
		oldBasket.set(b)
	})
}

function extractVendors(b: PedidoDatosCompra[]) {
	const ids = new Set<string>()
	b.forEach((l) => {
		ids.add(l.nombreVendedor)
	})
	return ids
}
